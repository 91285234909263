
  
  .asm-fade-enter  {
    opacity: 0;
    transform: translateX(-100%);
  }
  .asm-fade-enter-active  {
    opacity: 1;
    transform: translateX(0%);
  }
  .asm-fade-exit  {
    opacity: 1;
    transform: translateX(0%);
  }
  .asm-fade-exit-active {
    opacity: 0;
    transform: translateX(100%);
  }
  .asm-fade-enter-active ,
  .asm-fade-exit-active{
    transition: opacity 500ms, transform 500ms;
  }
  