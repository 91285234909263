* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Arial, sans-serif;
}

body {
  background-color: #f8f9fa;
  padding: 20px;
}

.table-container {
  width: 100%;
  margin: 0 auto;
  background-color: #fff;
  /* padding: 20px; */
  border-radius: 10px;
  box-shadow: 0px 2px 22px 0px rgba(0, 0, 0, 0.12);
  margin-bottom: 20px;
  /* border: 1px solid #ced4da; */
}

h2 {
  /* text-align: center;
    margin-bottom: 20px; */
  padding: 10px;
  color: #101828;
  font-weight: 600;
  font-size: 16px;
}

table {
  width: 100%;
  border-collapse: collapse;
  /* margin-bottom: 20px; */
  border: 1px solid #eaecf0; /* Border around the entire table */
}

thead {
  background-color: #fcfcfd;
}

thead th {
  padding: 10px;
  text-align: center;
  color: #333;
  font-weight: bold;
  border: 1px solid #eaecf0; /* Border for the header cells */
}

tbody td {
  padding: 10px;
  text-align: center;
  border-bottom: 1px solid #eaecf0;
  border-left: 1px solid #eaecf0; /* Border for the left side of the cells */
}

tbody tr td:last-child {
  border-right: 1px solid #eaecf0; /* Border for the right side of the last cell in a row */
}

tbody tr:hover {
  background-color: #f1f1f1;
}

.section-header {
  background-color: #e0e9e9;
  font-weight: bold;
  color: #333;
  border-top: 1px solid #eaecf0;
  border-bottom: 1px solid #eaecf0;
}

tbody tr td:nth-child(5), /* Difficulty Level */
    tbody tr td:nth-child(6), /* Correct Response */
    tbody tr td:nth-child(7), /* Score */
    tbody tr td:nth-child(8), /* You */
    tbody tr td:nth-child(9), /* All Student Avg */
    tbody tr td:nth-child(10) /* Top 10 percentile Avg */ {
  text-align: center;
}

tbody tr.section-header td {
  text-align: left;
}

/* Add borders to the entire table */
table,
thead th,
tbody td,
tbody tr td {
  border: 1px solid #eaecf0;
}
